import React, { useEffect, useState } from 'react';
import './App.css';
import RegistrationProgressBar from './components/assets/ProgressBar/progress-bar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// registration
const RegistrationForm = React.lazy(() => import("./components/assets/Registration/registration-form"));
const ProvementForm = React.lazy(() => import("./components/assets/Registration/provement-form"));
// message
const CompleteMessage1 = React.lazy(() => import("./components/assets/Message/step1-complete-message"));
const CompleteMessage2 = React.lazy(() => import("./components/assets/Message/step2-complete-message"));

const App = () => {

  useEffect(() => {
    document.title = "SubZero - Registration Form";
  }, []);

  return (
    <main className='App relative'>
      <Router>
        <ToastContainer />
        <RegistrationProgressBar/>

        <Routes>
          <Route path="/" element={<RegistrationForm />} />
          <Route path="form-submission/:register_user_id" element={<RegistrationForm />} />
          <Route path="document-submission/:register_user_id" element={<ProvementForm />} />
          <Route path="step1-complete-message/:register_user_name" element={<CompleteMessage1 />} />
          <Route path="step2-complete-message/:registerUserName" element={<CompleteMessage2 />} />
        </Routes>
      </Router>
    </main>
  );
}

export default App;