import React, { useEffect, useState, startTransition } from "react";
import "./progress-bar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useNavigate } from "react-router-dom";

const RegistrationProgressBar = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState('registrationPage');
  const [stepPercentage, setStepPercentage] = useState(0);

  const pathname = window.location.pathname;
  const match = pathname.match(/\/document-submission\/(\d+)$/);
  const registerUserId = match ? match[1] : null;

  useEffect(() => {
    if (pathname.startsWith("/form-submission")) {
      setPage("provementForm");
    } else if (pathname.startsWith("/document-submission")) {
      setPage("provementForm");
    } else {
      setPage("registrationPage");
    }

    let newPercentage = 0;

    switch (page) {
      case "registrationPage":
        newPercentage = 0;
        break;
      case "provementForm":
        newPercentage = 50;
        break;
      case "complete":
        newPercentage = 100;
        break;
      default:
        newPercentage = 0;
    }

    setStepPercentage(newPercentage);
  }, [page]);

  const handleStepClick = (step) => {
    switch (step) {
      case 1:
        if (pathname.startsWith("/document-submission")) {
          startTransition(() => {
            navigate(`/form-submission/${registerUserId}`);
          });
        }
        break;
      case 2:
        if (pathname.startsWith("/form-submission")) {
          startTransition(() => {
            navigate(-1);
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ProgressBar percent={stepPercentage}>
        {[1, 2, 3].map((stepIndex) => (
          <Step key={stepIndex}>
            {({ accomplished, index }) => (
              <div>
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  onClick={() => handleStepClick(index + 1)}
                >
                  <div className="step-circle">{index + 1}</div>
                </div>
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>

      <div className="step-label-container" style={{ marginTop: "45px" }}>
        <div className="steps">
          <label>Step 1: Registration Information</label>
        </div>
        <div className="steps">
          <label>Step 2: Document Submission</label>
        </div>
        <div className="steps">
          <label>Step 3: Completed</label>
        </div>
      </div>
    </>
  );
};

export default RegistrationProgressBar;